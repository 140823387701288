'use client'

import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import LoadingIcon from '@/components/SVG/LoadingIcon'

export default function ClientRootLayout({
  children
}: {
  children: React.ReactNode
}) {
  const router = useRouter()
  const path = usePathname()
  const [loading, setLoading] = useState(false)
  // To stop the first page transitioning in, unless you want that, in which case remove this bit of logic
  const [isInitialLoading, setIsInitialLoading] = useState(true)

  useEffect(() => {
    // Bind react router navigation event to all a tags
    const onClick = (event: any) => {
      const target = event.target as HTMLElement

      var foundTarget = target

      if (
        target.tagName?.toLowerCase() !== 'a' &&
        target.tagName?.toLowerCase() !== 'button'
      ) {
        const closestAnchor = target.closest('a')
        if (closestAnchor) {
          foundTarget = closestAnchor
        }
      }
      const lcTagName = foundTarget.tagName?.toLowerCase()

      if (lcTagName === 'a' || lcTagName === 'button') {
        const href = foundTarget.getAttribute('href')
        if (href && href.startsWith('/')) {
          event.preventDefault()
          if (href !== path) {
            setLoading(true)
          }
          router.push(href)
        }
      }
    }

    window.addEventListener('click', onClick)
    return () => window.removeEventListener('click', onClick)
  }, [router, path])

  useEffect(() => {
    window.scrollTo(0, 0)
    setLoading(false)
    setIsInitialLoading(false)
  }, [path])

  return (
    <>
      {loading &&
        <div className="fixed scale-[200%] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 text-black">
          <LoadingIcon className="animate-spin"></LoadingIcon>
        </div>
      }
      <motion.div
        style={{ height: '100%' }}
        initial={{
          x: isInitialLoading ? 0 : 300,
          opacity: isInitialLoading ? 1 : 0,
        }}
        animate={{
          x: (!isInitialLoading && loading) ? 300 : 0,
          opacity: (!isInitialLoading && loading) ? 0 : 1,
        }}
        transition={{
          type: 'spring',
          duration: 0.5,
        }}
      >
        {children}
      </motion.div>
    </>
  )
}